import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const destinationsData = [
  // Europe
  {
    id: 1,
    title: "Paris",
    description:
      "The city of love awaits with its romantic views, historic architecture, and amazing cuisine.",
    price: 1300,
    image: "/images/paris.jpg",
    continent: "Europe",
  },
  {
    id: 2,
    title: "Swiss Alps",
    description:
      "Explore the stunning peaks of the Swiss Alps, perfect for hiking and skiing adventures.",
    price: 2000,
    image: "/images/swiss-alps.jpg",
    continent: "Europe",
  },
  {
    id: 3,
    title: "Rome",
    description:
      "Discover the rich history of Rome, from ancient ruins to Renaissance art and architecture.",
    price: 1400,
    image: "/images/rome.jpg",
    continent: "Europe",
  },
  {
    id: 4,
    title: "Santorini",
    description:
      "Enjoy the breathtaking sunsets and stunning views of Santorini, Greece.",
    price: 1600,
    image: "/images/santorini.jpg",
    continent: "Europe",
  },
  {
    id: 5,
    title: "Barcelona",
    description:
      "Experience the vibrant culture and incredible architecture of Barcelona, Spain.",
    price: 1200,
    image: "/images/barcelona.jpg",
    continent: "Europe",
  },
  {
    id: 6,
    title: "Amsterdam",
    description:
      "Explore the charming canals and museums of Amsterdam, a city full of history and culture.",
    price: 1100,
    image: "/images/amsterdam.jpg",
    continent: "Europe",
  },

  // Asia
  {
    id: 7,
    title: "Bali",
    description:
      "Discover the rich culture and breathtaking landscapes of Bali, Indonesia.",
    price: 1200,
    image: "/images/bali.jpg",
    continent: "Asia",
  },
  {
    id: 8,
    title: "Tokyo",
    description:
      "Explore the futuristic and traditional sides of Tokyo, Japan's vibrant capital.",
    price: 1800,
    image: "/images/tokyo.jpg",
    continent: "Asia",
  },
  {
    id: 9,
    title: "Phuket",
    description:
      "Relax on the beautiful beaches of Phuket, Thailand's popular island destination.",
    price: 1400,
    image: "/images/phuket.jpg",
    continent: "Asia",
  },
  {
    id: 10,
    title: "Maldives",
    description:
      "Relax in the tropical paradise of the Maldives with crystal-clear waters and white sandy beaches.",
    price: 1500,
    image: "/images/maldives.jpg",
    continent: "Asia",
  },
  {
    id: 11,
    title: "Seoul",
    description:
      "Immerse yourself in the bustling city life of Seoul, South Korea, where modern meets tradition.",
    price: 1300,
    image: "/images/seoul.jpg",
    continent: "Asia",
  },
  {
    id: 12,
    title: "Singapore",
    description:
      "Experience the luxury and modernity of Singapore, a city known for its skyline and gardens.",
    price: 1600,
    image: "/images/singapore.jpg",
    continent: "Asia",
  },

  // North America
  {
    id: 13,
    title: "New York City",
    description:
      "Experience the vibrant life of NYC, from iconic landmarks to world-class dining.",
    price: 900,
    image: "/images/new-york.jpg",
    continent: "North America",
  },
  {
    id: 14,
    title: "Los Angeles",
    description:
      "Enjoy the glamour of Hollywood and the sunny beaches of Los Angeles, California.",
    price: 1000,
    image: "/images/los-angeles.jpg",
    continent: "North America",
  },
  {
    id: 15,
    title: "Miami",
    description:
      "Relax on the sun-soaked beaches of Miami, Florida, and enjoy the lively nightlife.",
    price: 1100,
    image: "/images/miami.jpg",
    continent: "North America",
  },
  {
    id: 16,
    title: "Toronto",
    description:
      "Explore the diverse city of Toronto, Canada's largest metropolis with a thriving arts scene.",
    price: 950,
    image: "/images/toronto.jpg",
    continent: "North America",
  },
  {
    id: 17,
    title: "Cancun",
    description:
      "Unwind in the tropical paradise of Cancun, Mexico, with its stunning beaches and resorts.",
    price: 1300,
    image: "/images/cancun.jpg",
    continent: "North America",
  },
  {
    id: 18,
    title: "Las Vegas",
    description:
      "Experience the excitement of Las Vegas, Nevada, known for its casinos, shows, and nightlife.",
    price: 1100,
    image: "/images/las-vegas.jpg",
    continent: "North America",
  },

];

const Products = () => {
  const [filter, setFilter] = useState(destinationsData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addDestination = (destination) => {
    navigate("/contact");
  };

  const filterDestination = (continent) => {
    const updatedList = destinationsData.filter(
      (item) => item.continent === continent
    );
    setFilter(updatedList);
  };

  const ShowDestinations = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => setFilter(destinationsData)}
          >
            All destinations
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterDestination("Europe")}
          >
            Europe
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterDestination("Asia")}
          >
            Asia
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterDestination("North America")}
          >
            North America
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterDestination("Africa")}
          >
            Africa
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterDestination("Australia")}
          >
            Australia
          </button>
        </div>

        {filter.map((destination) => {
          return (
            <div
              id={destination.id}
              key={destination.id}
              className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
            >
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src={destination.image}
                  alt={destination.title}
                  height={300}
                />
                <div className="card-body">
                  <h5 className="card-title">{destination.title}</h5>
                  <p className="card-text">{destination.description}</p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item lead">
                    $ {destination.price}
                  </li>
                </ul>
                <div className="card-body">
                  <button
                    className="btn btn-dark m-1"
                    onClick={() => addDestination(destination)}
                  >
                    Get more info
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Popular destinations</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          <ShowDestinations />
        </div>
      </div>
    </>
  );
};

export default Products;
