import React from "react";
import { Footer, Navbar } from "../components";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">About Us</h1>
        <hr />
        <p className="lead text-center">
          Welcome to Travelpers! Founded by a passionate husband-and-wife duo,
          John and Emily Smith, our journey began 10 years ago with a shared
          love for adventure and exploration. What started as a small travel
          agency in a cozy office has blossomed into a renowned travel company,
          connecting thousands of travelers with unforgettable experiences
          around the globe.
        </p>
        <p className="lead text-center">
          Our mission is to provide you with the best travel options tailored to
          your needs. With over a decade of experience in the travel industry,
          we have forged strong partnerships with airlines, hotels, and local
          guides to ensure you have the trip of a lifetime. Join us as we
          celebrate a decade of enriching lives through travel!
        </p>

        <h2 className="text-center py-4">Our Products</h2>
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="/images/adventure-tours.jpg"
                alt="Adventure Tours"
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Adventure Tours</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="/images/beach-holidays.jpg"
                alt="Beach Holidays"
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Beach Holidays</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="/images/cultural-experiences.jpg"
                alt="Cultural Experiences"
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Cultural Experiences</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img
                className="card-img-top img-fluid"
                src="/images/luxury-travel.jpg"
                alt="Luxury Travel"
                height={160}
              />
              <div className="card-body">
                <h5 className="card-title text-center">Luxury Travel</h5>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center py-4">Why Choose Us?</h2>
        <ul className="list-unstyled text-center">
          <li>🌍 Over 10 years of experience in the travel industry</li>
          <li>✈️ Personalized travel packages tailored to your preferences</li>
          <li>🤝 Partnerships with trusted airlines and hotels</li>
          <li>
            🌟 Dedicated customer support to assist you every step of the way
          </li>
          <li>💼 Special offers for group travel and corporate bookings</li>
        </ul>

        <h2 className="text-center py-4">Our Achievements</h2>
        <p className="text-center">
          From humble beginnings, we have grown into a reputable name in travel,
          helping thousands explore the world. We take pride in our customer
          satisfaction rate and our commitment to sustainable travel practices.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
