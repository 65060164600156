import { Navbar, Main, Product, Footer } from "../components";
import Advantages from "../components/Advantages";

function Home() {
  return (
    <>
      <Navbar />
      <Main />
      <Advantages />
      <Product />
      <Footer />
    </>
  );
}

export default Home;
