import React from "react";

const Advantages = () => {
  return (
    <section className="py-5 bg-light text-center">
      <div className="container">
        <h2 className="display-5 mb-4">Our Advantages</h2>
        <div className="row justify-content-center">
          <div className="col-md-3 col-sm-6 mb-4">
            {/* <FontAwesomeIcon icon={faAward} size="3x" /> */}
            <h5 className="mt-3">10 Years on the Market</h5>
            <p>
              We have over 10 years of experience providing the best travel
              services worldwide.
            </p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            {/* <FontAwesomeIcon icon={faUsers} size="3x" /> */}
            <h5 className="mt-3">Trusted by Thousands</h5>
            <p>Thousands of happy clients travel with us each year.</p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            {/* <FontAwesomeIcon icon={faHandshake} size="3x" /> */}
            <h5 className="mt-3">Exclusive Deals</h5>
            <p>We offer exclusive deals with top hotels and airlines.</p>
          </div>
          <div className="col-md-3 col-sm-6 mb-4">
            {/* <FontAwesomeIcon icon={faGlobe} size="3x" /> */}
            <h5 className="mt-3">Worldwide Coverage</h5>
            <p>Our services cover destinations across the globe.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
