import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center pt-4 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <h5>About Us</h5>
            <p>
              Travelpers is dedicated to providing exceptional travel
              experiences tailored to your needs.
            </p>
          </div>
          <div className="col-md-4 mb-3">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-decoration-none text-white">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-decoration-none text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/products" className="text-decoration-none text-white">
                  Our Products
                </a>
              </li>
              <li>
                <a href="/contact" className="text-decoration-none text-white">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-3">
            <h5>Connect with Us</h5>
            <div>
              <a
                className="text-white fs-4"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="text-white fs-4 ms-3"
                href="https://facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <div className="d-flex align-items-center justify-content-center">
          <p className="mb-0">Made with ❤️ by CreativeStudioVRGHS</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
